import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="h-auto flex justify-center items-center px-4">
      <div className="w-[90%] md:w-[70%]">
        <h1 className="contact-title mb-10 md:mb-20 mt-24 md:mt-32 text-center md:text-left">
          Contact Us!
          <br />
          Get in Touch with the Hunters.
        </h1>
        <div className="gradient-border-box-video-contact grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="">
            <h2 className="contact-form-title mb-7 text-center md:text-left">
              General inquiries.
            </h2>
            <p className="contact-form-sub-title text-center md:text-left">
              Let us know how we can help and we
              <br />
              will get back to you as soon as possible.
            </p>
          </div>
          <div className="flex flex-col">
            <input
              type="text"
              className="gradient-border-box-video-contact-input mb-7"
              placeholder="Name"
            />
            <input
              type="text"
              className="gradient-border-box-video-contact-input mb-7"
              placeholder="Email address"
            />
            <input
              type="text"
              className="gradient-border-box-video-contact-input mb-14"
              placeholder="Message"
            />
            <button className="stake-now-button relative bg-dark-blue-background text-custom-orange font-bold uppercase py-3 px-4 inline-block overflow-hidden group text-center">
              <span className="corner top-left"></span>
              <span className="corner top-right"></span>
              <span className="corner bottom-left"></span>
              <span className="corner bottom-right"></span>
              Submit
            </button>
          </div>
        </div>

        <div className="my-10 md:my-20 text-center">
          <h2 className="contact-updates-title">Stay up to date!</h2>
          <p className="contact-updates-content-bh">@BlockhuntersOrg</p>
        </div>
        <div className="">
          <h2 className="contact-updates-title text-center">Follow Us</h2>
          <div className="flex justify-center mt-7 mb-32">
            <div className="flex flex-row justify-evenly w-full md:w-1/2 items-center">
              <a
                href="https://twitter.com/blockhuntersOrg"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-twitter text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
              <a
                href="https://t.me/BlockhuntersAnnouncements"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-telegram text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
              <a
                href="https://block-hunters.medium.com"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-medium-ugly text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
              <a
                href="https://discord.com"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-discord text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
