import React from "react";
import dummyAvatar from "../../assets/dummy_avatar.png";
import "./About.css";

function About() {
  return (
    <div className="h-auto flex justify-center items-center">
      <div className="w-[90%] md:w-[70%] my-16 md:my-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="flex flex-col justify-start items-center">
            <h1 className="about-title self-start mt-12 md:mt-0 ml-0 md:ml-24 text-center md:text-left">
              Meet the
              <br />
              BlockHunters
              <br />
              Team
            </h1>
            <div className="flex flex-col my-10 md:my-20 items-center relative profile-card">
              <div className="image-container relative">
                <img
                  src={dummyAvatar}
                  alt="avatar"
                  width={128}
                  height={128}
                  className="profile-img relative z-10"
                />
                {/* Reversed and moved corners */}
                <div className="hover-corner-about top-left-about"></div>
                <div className="hover-corner-about top-right-about"></div>
                <div className="hover-corner-about bottom-left-about"></div>
                <div className="hover-corner-about bottom-right-about"></div>
              </div>
              <h2 className="about-icon-title mt-8 md:mt-12">Ioachim Viju</h2>
              <p className="about-icon-subtitle">BLOCKCHAIN ENGINEER</p>
              <div className="description opacity-0 transition-opacity">
                <p>
                  BlockHunters is a non-custodial staking service provider
                  currently supporting the most promising projects.
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center relative profile-card">
              <div className="image-container relative">
                <img
                  src={dummyAvatar}
                  alt="avatar"
                  width={128}
                  height={128}
                  className="profile-img relative z-10"
                />
                {/* Reversed and moved corners */}
                <div className="hover-corner-about top-left-about"></div>
                <div className="hover-corner-about top-right-about"></div>
                <div className="hover-corner-about bottom-left-about"></div>
                <div className="hover-corner-about bottom-right-about"></div>
              </div>
              <h2 className="about-icon-title mt-8 md:mt-12">Ioachim Viju</h2>
              <p className="about-icon-subtitle">BLOCKCHAIN ENGINEER</p>
              <div className="description opacity-0 transition-opacity">
                <p>
                  BlockHunters is a non-custodial staking service provider
                  currently supporting the most promising projects.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-end items-center mt-10 md:mt-20">
            <div className="flex flex-col items-center relative profile-card">
              <div className="image-container relative">
                <img
                  src={dummyAvatar}
                  alt="avatar"
                  width={128}
                  height={128}
                  className="profile-img relative z-10"
                />
                {/* Reversed and moved corners */}
                <div className="hover-corner-about top-left-about"></div>
                <div className="hover-corner-about top-right-about"></div>
                <div className="hover-corner-about bottom-left-about"></div>
                <div className="hover-corner-about bottom-right-about"></div>
              </div>
              <h2 className="about-icon-title mt-8 md:mt-12">Ioachim Viju</h2>
              <p className="about-icon-subtitle">BLOCKCHAIN ENGINEER</p>
              <div className="description opacity-0 transition-opacity">
                <p>
                  BlockHunters is a non-custodial staking service provider
                  currently supporting the most promising projects.
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center my-10 md:my-20 relative profile-card">
              <div className="image-container relative">
                <img
                  src={dummyAvatar}
                  alt="avatar"
                  width={128}
                  height={128}
                  className="profile-img relative z-10"
                />
                {/* Reversed and moved corners */}
                <div className="hover-corner-about top-left-about"></div>
                <div className="hover-corner-about top-right-about"></div>
                <div className="hover-corner-about bottom-left-about"></div>
                <div className="hover-corner-about bottom-right-about"></div>
              </div>
              <h2 className="about-icon-title mt-8 md:mt-12">Ioachim Viju</h2>
              <p className="about-icon-subtitle">BLOCKCHAIN ENGINEER</p>
              <div className="description opacity-0 transition-opacity">
                <p>
                  BlockHunters is a non-custodial staking service provider
                  currently supporting the most promising projects.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-16 md:mt-32">
          <h1 className="about-title text-center md:text-left">Hunt for the best stake!</h1>
          <div className="flex flex-col md:flex-row w-full md:w-1/2 justify-around items-center mt-10 space-y-6 md:space-y-0">
            <button className="stake-now-button w-64 relative bg-dark-blue-background text-white font-bold uppercase py-3 px-4 inline-block overflow-hidden group text-center">
              <span className="corner top-left"></span>
              <span className="corner top-right"></span>
              <span className="corner bottom-left"></span>
              <span className="corner bottom-right"></span>
              About Blockhunters
            </button>
            <button className="stake-now-button w-64 relative bg-dark-blue-background text-custom-orange font-bold uppercase py-3 px-4 inline-block overflow-hidden group text-center">
              <span className="corner top-left"></span>
              <span className="corner top-right"></span>
              <span className="corner bottom-left"></span>
              <span className="corner bottom-right"></span>
              Contact us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
